import React from "react";
import { useTranslation } from "react-i18next";
export default function FallbackUI() {
  const { t } = useTranslation();
  return (
    <>
      <div className="container error-boundary">
        <div className="col-12">
          <h1 className="section-title text-center">
            {t("something_went_wrong")}
          </h1>
          <p className="error-boundary-text">{t("page_load_error_msg")}</p>
          <div className="list-next d-flex justify-content-center">
            <button
              className="btn btn-primary"
              type="button"
              onClick={() => document.location.reload(true)}
            >
              {t("reload")}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
