import { applyMiddleware, legacy_createStore as createStore, compose } from "redux";
import {thunk} from 'redux-thunk';

// import { composeWithDevTools } from 'redux-devtools-extension'
import reducers from "./reducers"

const store = createStore(reducers, compose(applyMiddleware(thunk)));

// const initialState = {};

// const middleWare = [thunk];

// const store = createStore(reducers, initialState, compose(
//     applyMiddleware(...middleWare)
// ));
export default store;